import { TranslationKey } from "./translation-key";

export enum Language {
  en = "en",
  ja = "ja",
}

export const AdminLanguageKey = {
  name: "zap-web-language",
};

export const Languages = [
  { id: Language.en, name: TranslationKey.English },
  { id: Language.ja, name: TranslationKey.Japanese },
];
