import React from "react";
import { FormHelperText, Grid, Typography } from "@mui/material";
import { RichTextEditor } from "@mantine/rte";
import { useTranslation } from "react-i18next";
import { TranslationKey } from "../i18n";

export const TextEditor: React.FC<{
  id: string;
  formProperty: any;
  label: TranslationKey;
  md?: number;
}> = ({ id, formProperty, label, md = 12 }) => {
  const { t } = useTranslation();

  return (
    <Grid item md={md} sm={12} xs={12}>
      <Typography
        component="label"
        sx={{
          display: "block",
          fontWeight: "500",
          fontSize: "14px",
          mb: "10px",
        }}
      >
        {t(label)}
      </Typography>
      <RichTextEditor
        id={id}
        aria-required
        controls={[
          ["bold", "italic", "underline", "link"],
          ["unorderedList", "h1", "h2", "h3", "h4", "h5", "h6"],
          ["sup", "sub"],
          ["alignLeft", "alignCenter", "alignRight"],
        ]}
        value={formProperty.value}
        onChange={formProperty.changeHandler}
      />
      {formProperty.error && (
        <FormHelperText error={!formProperty.valid}>
          {formProperty.error}
        </FormHelperText>
      )}
    </Grid>
  );
};
