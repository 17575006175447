import Swal from 'sweetalert2';

export const swalDelete = async () => {
  const result = await Swal.fire({
    title: 'Are you sure you want to delete?',
    text: "You won't be able to revert.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#dc3545',
    cancelButtonColor: '#28a745',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  });

  return result.value;
};

export const swalResetPassword = async () => {
  const result = await Swal.fire({
    title: "Are you sure you want to reset this user's password?",
    text: "Password will be reset to 'welcome1234'.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#dc3545',
    cancelButtonColor: '#28a745',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  });

  return result.value;
};

export const swalUnsavedChanges = async () => {
  const result = await Swal.fire({
    title: 'Unsaved Changes',
    text: 'Are you sure you want to cancel?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#dc3545',
    cancelButtonColor: '#28a745',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  });

  return result.value;
};
