import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import { TranslationKey } from "../i18n";

export const IconButtonWithTooltip: React.FC<{
  title: TranslationKey;
  color:
    | "inherit"
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  disabled?: boolean;
  icon: React.ReactNode;
  onClick: () => void;
}> = ({ title, disabled, color, icon, onClick }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(title)} placement="top">
      <IconButton
        color={color}
        disabled={disabled}
        size="small"
        onClick={onClick}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};
