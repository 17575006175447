import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { reportUserReasons } from "../constants";
import { TranslationKey } from "../i18n";

export const ReportReasonText: React.FC<{
  formProperty: any;
  md?: number;
}> = ({ formProperty, md = 12 }) => {
  const { t } = useTranslation();

  const getValue = () => {
    let value = "";

    if (formProperty.value) {
      const reason = reportUserReasons.find(
        (r) => r.value === +formProperty.value
      );

      if (reason) {
        value = t(reason.label);
      }
    }

    return value;
  };

  return (
    <Grid item md={md} sm={12} xs={12}>
      <Typography
        component="label"
        sx={{
          display: "block",
          fontWeight: "500",
          fontSize: "14px",
          mb: "10px",
        }}
      >
        {t(TranslationKey.Reason)}
      </Typography>
      <TextField
        id="reason-id"
        disabled
        fullWidth
        type="text"
        value={getValue()}
      />
    </Grid>
  );
};
