export const Logo = () => {
  return (
    <img
      alt="ZAP"
      src="/images/logo.png"
      style={{
        borderRadius: 20,
        display: "block",
        marginBottom: 30,
        marginLeft: "auto",
        marginRight: "auto",
      }}
      width={100}
    />
  );
};
