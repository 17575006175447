import React from "react";
import { Divider, IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import TranslateIcon from "@mui/icons-material/Translate";
import { ActionIdentifier } from "../../constants";
import { AdminLanguageKey, Language } from "../../i18n";
import { useStore } from "../../store";

const LanguageMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useStore(false)[1];
  const open = Boolean(anchorEl);
  const i18n = useTranslation()[1];

  const changeLanguage = (language: string) => {
    dispatch(ActionIdentifier.Language, language);
    handleClose();
    i18n.changeLanguage(language);
    localStorage.setItem(AdminLanguageKey.name, language);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls={open ? "account-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        className="ml-2 for-dark-notification"
        onClick={handleClick}
        size="small"
        sx={{
          backgroundColor: "#f5f5f5",
          width: "40px",
          height: "40px",
          p: 0,
        }}
      >
        <TranslateIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        id="language-menu"
        open={open}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            padding: "5px 20px 5px",
            borderRadius: "10px",
            boxShadow: "0px 10px 35px rgba(50, 110, 189, 0.2)",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem>
          <Link
            component="button"
            underline="none"
            onClick={() => changeLanguage(Language.en)}
          >
            English
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ justifyContent: "right" }}>
          <Link
            component="button"
            underline="none"
            onClick={() => changeLanguage(Language.ja)}
          >
            日本語
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguageMenu;
