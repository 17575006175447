import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Authentication } from "../../services";
import { SidebarItem } from "./SidebarData";
import "./SubMenu.css";

const SidebarLabel = styled("span")(() => ({
  position: "relative",
  top: "-3px",
}));

const SubMenu: React.FC<{
  item: SidebarItem;
}> = ({ item }) => {
  const [currentPath, setCurrentPath] = useState("");
  const router = useLocation();
  const [subnav, setSubnav] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentPath(router.pathname);
  }, [router]);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      {(!item.roles || Authentication.allowedRoles(item.roles)) && (
        <>
          <NavLink
            to={item.path}
            onClick={item.subNav && showSubnav}
            className={`sidebarLink ${
              currentPath === item.path && "sidebarLinkActive"
            }`}
          >
            <div>
              {<item.icon />}
              <SidebarLabel className="ml-1">{t(item.title)}</SidebarLabel>
            </div>
            <div>
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div>
          </NavLink>
          {subnav &&
            item.subNav &&
            item.subNav.map((item, index: number) => {
              return (
                <NavLink
                  to={item.path}
                  key={index}
                  className={`sidebarLink2 ${
                    currentPath === item.path && "sidebarLinkActive2"
                  }`}
                >
                  {<item.icon />}
                  {t(item.title)}
                </NavLink>
              );
            })}
        </>
      )}
    </>
  );
};

export default SubMenu;
