import axios from "axios";
import { toast } from "react-toastify";
import { Routes, Token } from "../constants";

axios.interceptors.response.use(
  (success) => {
    return Promise.resolve(success.data);
  },
  (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      toast.error("An unexpected error occurred.");
    }

    return Promise.reject(error.response.data);
  }
);

const url = "https://nz1rtz9vta.execute-api.ap-northeast-1.amazonaws.com/prod";

const get = async <T>(
  route: Routes,
  query?: Record<string, any> | undefined
): Promise<T> => {
  return await axios.get(`${url}/${route}${toQueryString(query)}`, headers());
};

const getAll = async (route: Routes): Promise<any> => {
  return await axios.get(`${url}/${route}`, headers());
};

const put = async (route: Routes, data: any) => {
  return await axios.put(`${url}/${route}`, data, headers());
};

const changeActive = async (route: Routes, id: any, active: boolean) => {
  return await axios.put(`${url}/${route}/${id}/${active}`, null, headers());
};

const _delete = async (route: Routes, id: any) => {
  return await axios.delete(`${url}/${route}/${id}`, headers());
};

const deleteUserByEmail = async (email: string, otp: number) => {
  return await axios.delete(
    `${url}/${Routes.UsersDeleteByEmail}/${email}/${otp}`,
    headers()
  );
};

const headers = () => {
  return {
    headers: {
      Authorization: localStorage.getItem(Token.name),
    },
  };
};

const toQueryString = (query: Record<string, any> | undefined) => {
  const queryString = [];

  if (query) {
    for (const property in query) {
      if (query[property]) {
        queryString.push(
          `${encodeURIComponent(property)}=${encodeURIComponent(
            query[property]
          )}`
        );
      }
    }
  }

  if (queryString.length > 0) {
    return "?" + queryString.join("&");
  } else {
    return "";
  }
};

const updateImages = async (images: {
  toDelete?: string[];
  toUpload: { file: any; name: string }[];
}) => {
  return await axios.put(`${url}/s3/update-images`, images, headers());
};

export const Http = {
  get,
  getAll,
  put,
  changeActive,
  delete: _delete,
  deleteUserByEmail,
  updateImages,
};
