import { TranslationKey } from "./translation-key";

export const en = {
  translation: {
    [TranslationKey.Actions]: "Actions",
    [TranslationKey.Active]: "Active",
    [TranslationKey.AdminUsers]: "Admin Users",
    [TranslationKey.Anonymous]: "Anonymous",
    [TranslationKey.AppName]: "ZAP -Make Gaming Friends-",
    [TranslationKey.Block]: "Block",
    [TranslationKey.Blocked]: "Blocked",
    [TranslationKey.BlockedDevices]: "Blocked Devices",
    [TranslationKey.Browse]: "Browse",
    [TranslationKey.Cancel]: "Cancel",
    [TranslationKey.ChangePassword]: "Change Password",
    [TranslationKey.Confirm]: "Confirm",
    [TranslationKey.ConfirmNewPassword]: "Confirm New Password",
    [TranslationKey.Created]: "Created.",
    [TranslationKey.CreatedAt]: "Created at",
    [TranslationKey.Delete]: "Delete",
    [TranslationKey.Deleted]: "Deleted.",
    [TranslationKey.DeleteMyAccount]: "Delete my Account",
    [TranslationKey.Details]: "Details",
    [TranslationKey.DisplayName]: "Display Name",
    [TranslationKey.Documents]: "Documents",
    [TranslationKey.DropFilesOrClick]: "Drop files here or click to upload.",
    [TranslationKey.DropFilesOrClickTo]: "Drop files here or click to",
    [TranslationKey.DuplicateName]: "Duplicate name.",
    [TranslationKey.DuplicateUsername]: "Duplicate username.",
    [TranslationKey.Edit]: "Edit",
    [TranslationKey.Email]: "Email",
    [TranslationKey.English]: "English",
    [TranslationKey.Home]: "Home",
    [TranslationKey.Id]: "Id",
    [TranslationKey.InvalidEmail]: "Invalid email address.",
    [TranslationKey.InvalidNumber]: "Invalid number.",
    [TranslationKey.Japanese]: "Japanese",
    [TranslationKey.Language]: "Language",
    [TranslationKey.Login]: "Login",
    [TranslationKey.LoginInformationError]: "Login information error.",
    [TranslationKey.Logout]: "Logout",
    [TranslationKey.MoveDown]: "Move Down",
    [TranslationKey.MoveUp]: "Move Up",
    [TranslationKey.Name]: "Name",
    [TranslationKey.New]: "New",
    [TranslationKey.NewPassword]: "New Password",
    [TranslationKey.NoDataAvailable]: "No data available.",
    [TranslationKey.None]: "None",
    [TranslationKey.OldPassword]: "Old Password",
    [TranslationKey.OTP]: "OTP",
    [TranslationKey.Password]: "Password",
    [TranslationKey.PasswordEasy]: "The new password is too easy to guess.",
    [TranslationKey.PasswordNotIdentical]:
      "Confirmation password is not identical.",
    [TranslationKey.PasswordRequirement_1]: "Minimum 8, maximum 26 characters.",
    [TranslationKey.PasswordRequirement_2]:
      "Must include at least 1 uppercase letter, 1 lowercase letter and 1 number.",
    [TranslationKey.PleaseWait]: "Please wait...",
    [TranslationKey.PrivacyPolicy]: "Privacy Policy",
    [TranslationKey.Reason]: "Reason",
    [TranslationKey.Recruitments]: "Recruitments",
    [TranslationKey.RemoveAll]: "Remove All",
    [TranslationKey.ReportedAt]: "Reported at",
    [TranslationKey.ReportedByUserId]: "Reported by User Id",
    [TranslationKey.ReportedUsers]: "Reported Users",
    [TranslationKey.Required]: "Required",
    [TranslationKey.Role]: "Role",
    [TranslationKey.Save]: "Save",
    [TranslationKey.Saving]: "Saving...",
    [TranslationKey.Search]: "Search",
    [TranslationKey.Stats]: "Stats",
    [TranslationKey.TermsOfUse]: "Terms of Use",
    [TranslationKey.Text]: "Text",
    [TranslationKey.ThroughYourMachine]: "through your machine",
    [TranslationKey.Timestamp]: "Timestamp",
    [TranslationKey.Title]: "Title",
    [TranslationKey.Updated]: "Updated.",
    [TranslationKey.UserDisplayName]: "User display name",
    [TranslationKey.UserNotFound]: "User not found",
    [TranslationKey.Username]: "Username",
    [TranslationKey.Users]: "Users",
    [TranslationKey.YourPassword]: "Your Password,",
    [TranslationKey.Value]: "Value",
    [TranslationKey.View]: "View",
    [TranslationKey.WeakPassword]: "Weak password.",

    // Report User
    [TranslationKey.SeekingRomanticEncounters]: "Seeking romantic encounters",
    [TranslationKey.HateSpeech]: "Hate speech",
    [TranslationKey.NudeSexual]: "Nude or sexual content",
    [TranslationKey.Violent]: "Violent content",
    [TranslationKey.Harassment]: "Harassment",
    [TranslationKey.Impersonation]: "Impersonation",
    [TranslationKey.FraudulentActivity]: "Fraudulent activity",
    [TranslationKey.Other]: "Other",
  },
};
