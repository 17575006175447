import { TranslationKey } from "../i18n";

export enum ReportUserReasonId {
  SeekingRomanticEncounters = 1,
  HateSpeech,
  NudeSexual,
  Violent,
  Harassment,
  Impersonation,
  FraudulentActivity,
  Other = 99,
}

export const reportUserReasons = [
  {
    label: TranslationKey.SeekingRomanticEncounters,
    value: ReportUserReasonId.SeekingRomanticEncounters,
  },
  {
    label: TranslationKey.HateSpeech,
    value: ReportUserReasonId.HateSpeech,
  },

  {
    label: TranslationKey.NudeSexual,
    value: ReportUserReasonId.NudeSexual,
  },
  {
    label: TranslationKey.Violent,
    value: ReportUserReasonId.Violent,
  },
  {
    label: TranslationKey.Harassment,
    value: ReportUserReasonId.Harassment,
  },
  {
    label: TranslationKey.Impersonation,
    value: ReportUserReasonId.Impersonation,
  },

  {
    label: TranslationKey.FraudulentActivity,
    value: ReportUserReasonId.FraudulentActivity,
  },
  {
    label: TranslationKey.Other,
    value: ReportUserReasonId.Other,
  },
];

