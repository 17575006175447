import React from "react";
import {
  Checkbox as MaterialCheckbox,
  Grid,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslationKey } from "../i18n";

export const Checkbox: React.FC<{
  id: string;
  label: TranslationKey;
  disabled: boolean;
  checked: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ id, label, checked, onClick, disabled }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <MaterialCheckbox
            id={id}
            checked={checked}
            disabled={disabled}
            onClick={onClick}
          />
        }
        label={t(label)}
      />
    </Grid>
  );
};
