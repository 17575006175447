export enum TranslationKey {
  Actions = "ACTIONS",
  Active = "ACTIVE",
  AdminUsers = "ADMIN_USERS",
  Anonymous = "ANONYMOUS",
  AppName = "APP_NAME",
  Block = "BLOCK",
  Blocked = "BLOCKED",
  BlockedDevices = "BLOCKED_DEVICES",
  Browse = "BROWSE",
  Cancel = "CANCEL",
  ChangePassword = "CHANGE_PASSWORD",
  Confirm = "CONFIRM",
  ConfirmNewPassword = "CONFIRM_NEW_PASSWORD",
  Created = "CREATED",
  CreatedAt = "CREATED_AT",
  Delete = "DELETE",
  Deleted = "DELETED",
  DeleteMyAccount = "DELETE_MY_ACCOUNT",
  Details = "DETAILS",
  DisplayName = "DISPLAY_NAME",
  Documents = "DOCUMENTS",
  DropFilesOrClick = "DROP_FILES_OR_CLICK",
  DropFilesOrClickTo = "DROP_FILES_OR_CLICK_TO",
  DuplicateName = "DUPLICATED_NAME",
  DuplicateUsername = "DUPLICATED_USERNAME",
  Edit = "EDIT",
  Email = "EMAIL",
  English = "ENGLISH",
  Home = "HOME",
  Id = "ID",
  InvalidEmail = "INVALID_EMAIL",
  InvalidNumber = "INVALID_NUMBER",
  Japanese = "JAPANESE",
  Language = "LANGUAGE",
  Login = "LOGIN",
  LoginInformationError = "LOGIN_INFO_ERROR",
  Logout = "LOGOUT",
  MoveDown = "MOVE_DOWN",
  MoveUp = "MOVE_UP",
  Name = "NAME",
  New = "NEW",
  NewPassword = "NEW_PASSWORD",
  NoDataAvailable = "NO_DATA_AVAILABLE",
  None = "NONE",
  OldPassword = "OLD_PASSWORD",
  OTP = "OTP",
  Password = "PASSWORD",
  PasswordEasy = "PASSWORD_EASY",
  PasswordNotIdentical = "PASSWORD_NOT_IDENTICAL",
  PasswordRequirement_1 = "PASSWORD_REQUIREMENT_1",
  PasswordRequirement_2 = "PASSWORD_REQUIREMENT_2",
  PleaseWait = "PLEASE_WAIT",
  PrivacyPolicy = "PRIVACY_POLICY",
  Reason = "REASON",
  Recruitments = "RECRUITMENTS",
  RemoveAll = "REMOVE_ALL",
  ReportedAt = "REPORTED_AT",
  ReportedByUserId = "REPORTED_BY_USER_ID",
  ReportedUsers = "REPORTED_USERS",
  Required = "REQUIRED",
  ResetPassword = "RESET_PASSWORD",
  Role = "ROLE",
  Save = "SAVE",
  Saving = "SAVING",
  Search = "SEARCH",
  Stats = "STATS",
  Title = "TITLE",
  TermsOfUse = "TERMS_OF_USE",
  Text = "TEXT",
  ThroughYourMachine = "THROUGH_YOUR_MACHINE",
  Timestamp = "TIMESTAMP",
  Updated = "UPDATED",
  UserDisplayName = "USER_DISPLAY_NAME",
  UserNotFound = "USER_NOT_FOUND",
  Username = "USERNAME",
  Users = "USERS",
  YourPassword = "YOUR_PASSWORD",
  Value = "VALUE",
  View = "VIEW",
  WeakPassword = "WEAK_PASSWORD",

  // Report User
  SeekingRomanticEncounters = "S_R_E",
  HateSpeech = "H_S",
  NudeSexual = "N_S",
  Violent = "VIOLENT",
  Harassment = "HARASSMENT",
  Impersonation = "IMPERSONATION",
  FraudulentActivity = "F_A",
  Other = "OTHER",
}
