import { ThemeProvider, CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ScrollToTop } from "./components";
import { Anonymous, Authorized } from "./layouts";
import { useStore } from "./store";

// Styles
import "./styles/chat.css";
import "./styles/dark.css";
import "./styles/globals.css";
import "./styles/remixicon.css";
import "./styles/rtl.css";

// Theme Styles
import theme from "./styles/theme";

function App() {
  const { language } = useStore()[0];
  const { loggedIn } = useStore()[0];

  return (
    <div lang={language}>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {loggedIn ? <Authorized /> : <Anonymous />}
        <ScrollToTop />
      </ThemeProvider>
    </div>
  );
}

export default App;
