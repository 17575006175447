import { Authentication } from "../services";
import { initStore } from ".";

const configureStore = () => {
  const actions = {
    LOGGEDIN: (state: { loggedIn: boolean }, value: boolean) => ({
      loggedIn: (state.loggedIn = value),
    }),
  };

  initStore(actions, { loggedIn: Authentication.loggedIn() });
};

export default configureStore;
