import React from "react";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslationKey } from "../i18n";

export const SwitchWithLabel: React.FC<{
  id: string;
  label: TranslationKey;
  formProperty: any;
  md?: number;
  onChange?: () => void;
}> = ({ id, label, formProperty, md = 12, onChange }) => {
  const { t } = useTranslation();

  const onClick = () => {
    formProperty.changeHandler(!formProperty.value);

    if (onChange) onChange();
  };

  return (
    <Grid item md={md} sm={12} xs={12}>
      <FormControlLabel
        id={id}
        control={
          <Switch
            id={`${id}-switch`}
            checked={formProperty.value || false}
            onClick={onClick}
          />
        }
        label={t(label)}
      />
    </Grid>
  );
};
