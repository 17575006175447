import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import configureAuthenticationStore from "./store/authentication-store";
import configureLanguageStore from "./store/language-store";
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

configureAuthenticationStore();
configureLanguageStore();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
