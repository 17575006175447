import React, { useEffect, useState } from "react";
import { Box, Button, Card, Grid, IconButton, Typography } from "@mui/material";
import cloneDeep from "lodash.clonedeep";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { DataTable } from "./DataTable";
import { Form } from "../utils";
import { Http } from "../services";
import { Routes, swalDelete } from "../constants";
import { SaveCancelButtonGroup, SearchField, Spinner } from ".";
import { TranslationKey } from "../i18n";

export interface Column {
  key: string;
  name: TranslationKey;
  isBoolean?: boolean;
  isDate?: boolean;
  mapping?: { id: any; [key: string]: any }[];
  mappingTextPropertyName?: string;
  translateMappingTextProperty?: boolean;
}

export enum DataAction {
  Add = 1,
  Edit,
  Delete,
  Sort,
  View,
}

export interface FormSaveButton {
  action: (item: any) => Promise<void>;
  color:
    | "inherit"
    | "warning"
    | "success"
    | "error"
    | "info"
    | "secondary"
    | "primary";
  icon: React.ReactNode;
  label: TranslationKey;
}

export interface DataHandlerProperties {
  title: TranslationKey;
  actions?: DataAction[];
  activeColumn?: boolean;
  children?: React.ReactNode;
  columns: Column[];
  form?: Form;
  formSaveButton?: FormSaveButton;
  route: Routes;
  searchPropertyLabel?: string;
  onCancel?: () => void;
  onAdd?: () => void;
  onEdit?: (item: any) => void;
  onSaveCompleted?: (resposne: any) => Promise<void>;
}

export const DataHandler: React.FC<DataHandlerProperties> = ({
  title,
  actions,
  activeColumn,
  children,
  columns,
  form,
  formSaveButton,
  route,
  searchPropertyLabel,
  onCancel,
  onAdd,
  onEdit,
  onSaveCompleted,
}) => {
  const [busy, setBusy] = useState(false);
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [editing, setEditing] = useState(false);
  const [exclusiveStartKey, setExclusiveStartKey] =
    useState<Record<string, any>>();
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const [view, setView] = useState("view");

  useEffect(
    () => {
      getDataHandler();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const addHandler = () => {
    if (onAdd) {
      onAdd();
    } else {
      setView("form");
    }
  };

  const cancelHandler = (event: any) => {
    if (!form) return;

    event.preventDefault();
    form.__clear();
    setEditing(false);
    setView("view");

    if (onCancel) onCancel();
  };

  const changeActiveHandler = async (item: Record<string, any>) => {
    setBusy(true);

    try {
      const updatedItem = { ...item };
      updatedItem.active = !updatedItem.active;
      await Http.changeActive(route, item.id, updatedItem.active);
      updateData(updatedItem);
    } catch (error: any) {
      toast.error(error.message);
    }

    setBusy(false);
  };

  const deleteHandler = async (index: number) => {
    if (!form) return;

    try {
      const result = await swalDelete();

      if (result) {
        setBusy(true);
        const item: Record<string, any> = data[index];
        await Http.delete(route, item[form.__primaryKey]);

        setData(
          data.filter((i) => i[form.__primaryKey] !== item[form.__primaryKey])
        );

        toast.info(t(TranslationKey.Deleted));
      }
    } catch (error: any) {
      toast.error(error.message);
    }

    setBusy(false);
  };

  const editHandler = (item: Record<string, any>) => {
    if (!form) return;

    form.__setValue(cloneDeep(item));
    setEditing(true);
    setView("form");

    if (onEdit) {
      onEdit(item);
    }
  };

  const getDataHandler = async () => {
    setBusy(true);

    try {
      const response: {
        Items?: Record<string, any>[];
        LastEvaluatedKey?: Record<string, any>;
      } = await Http.get(route, exclusiveStartKey);

      if (response.Items) setData(data.concat(response.Items));

      setExclusiveStartKey(response.LastEvaluatedKey);
    } catch (error: any) {
      toast.error(error.message);
    }

    setBusy(false);
  };

  const saveHandler = async (event: any) => {
    if (!form) return;

    event.preventDefault();

    if (!form.__validate()) return;

    setBusy(true);

    try {
      if (formSaveButton?.action) {
        await formSaveButton.action(form.__value);
      } else {
        const response: Record<string, any> = await Http.put(
          route,
          form.__value
        );

        updateData(response);

        if (onSaveCompleted) await onSaveCompleted(response);

        if (editing) {
          setEditing(false);
          toast.info(t(TranslationKey.Updated));
        } else {
          toast.success(t(TranslationKey.Created));
        }
      }

      form.__clear();
      setView("view");
    } catch (error: any) {
      toast.error(error.message);
    }

    setBusy(false);
  };

  const searchHandler = async (searchTerm: string) => {
    setBusy(true);

    try {
      const query: Record<string, any> = {};

      if (searchPropertyLabel && searchTerm) {
        query[searchPropertyLabel] = searchTerm;
      }

      const response: {
        Items?: Record<string, any>[];
        LastEvaluatedKey?: Record<string, any>;
      } = await Http.get(route, query);

      setData(response.Items || []);
    } catch (error: any) {
      toast.error(error.message);
    }

    setBusy(false);
  };

  const updateData = (response: Record<string, any>) => {
    const items = [...data];
    const updatedItem = items.find((i) => i.id === response.id);

    if (updatedItem) {
      items.splice(items.indexOf(updatedItem), 1, response);
    } else {
      items.splice(0, 0, response);
    }

    setData(items);
  };

  return (
    <Card
      sx={{
        boxShadow: "none",
        borderRadius: "10px",
        p: "25px",
        mb: "15px",
      }}
    >
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 500,
          mb: "10px",
        }}
      >
        {t(title)}
      </Typography>
      {view === "view" ? (
        <>
          <Box
            className="for-dark-bottom-border"
            sx={{
              display: "flex",
              justifyContent: searchPropertyLabel
                ? "space-between"
                : "flex-end",
              borderBottom: "1px solid #EEF0F7",
              paddingBottom: "10px",
              mb: "20px",
            }}
          >
            {searchPropertyLabel && (
              <SearchField
                busy={busy}
                searchTerm={searchTerm}
                onBlur={searchHandler}
                setSearchTerm={setSearchTerm}
              />
            )}
            {actions?.includes(DataAction.Add) && (
              <Button
                disabled={busy}
                sx={{
                  borderRadius: "8px",
                  color: "#fff !important",
                  fontSize: "13px",
                  fontWeight: "500",
                  padding: "12px 20px",
                  textTransform: "capitalize",
                }}
                variant="contained"
                onClick={addHandler}
              >
                <AddIcon
                  sx={{ position: "relative", top: "-1px" }}
                  className="mr-5px"
                />{" "}
                {t(TranslationKey.New)}
              </Button>
            )}
          </Box>
          {busy ? (
            <Spinner />
          ) : data.length === 0 ? (
            <div className="alert alert-warning" role="alert">
              {t(TranslationKey.NoDataAvailable)}
            </div>
          ) : (
            <>
              <DataTable
                actions={actions}
                activeColumn={activeColumn || false}
                busy={busy}
                columns={columns}
                data={data}
                changeActiveHandler={changeActiveHandler}
                editHandler={editHandler}
                deleteHandler={deleteHandler}
              />
              <div>
                <Typography
                  component="label"
                  sx={{
                    fontWeight: "500",
                    fontSize: "11px",
                  }}
                >
                  Viewing {data.length} item(s)
                </Typography>
                <IconButton
                  color="secondary"
                  disabled={busy || !exclusiveStartKey}
                  onClick={getDataHandler}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </div>
            </>
          )}
        </>
      ) : (
        <form>
          <Grid container padding={2} spacing={2}>
            {children}
          </Grid>
          <SaveCancelButtonGroup
            busy={busy}
            formSaveButton={formSaveButton}
            cancelHandler={cancelHandler}
            saveHandler={saveHandler}
          />
        </form>
      )}
    </Card>
  );
};
