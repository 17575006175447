import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslationKey } from "../i18n";

export const Input: React.FC<{
  id: string;
  disabled?: boolean;
  formProperty: any;
  label: TranslationKey;
  md?: number;
  multiline?: boolean;
  readOnly?: boolean;
  rows?: number;
  type?: "email" | "number" | "password" | "text";
}> = ({
  id,
  disabled,
  label,
  formProperty,
  md = 12,
  multiline,
  readOnly,
  rows = 0,
  type = "text",
}) => {
  const { t } = useTranslation();

  return (
    <Grid item md={md} sm={12} xs={12}>
      <Typography
        component="label"
        sx={{
          display: "block",
          fontWeight: "500",
          fontSize: "14px",
          mb: "10px",
        }}
      >
        {t(label)}
      </Typography>
      <TextField
        id={id}
        autoComplete="off"
        error={!formProperty.valid}
        disabled={disabled}
        fullWidth
        helperText={formProperty.error}
        InputProps={{ readOnly }}
        multiline={multiline}
        rows={rows}
        type={type}
        value={formProperty.value === null ? "" : formProperty.value}
        onBlur={formProperty.blurHandler}
        onChange={formProperty.changeHandler}
      />
    </Grid>
  );
};
