import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import RichTextEditor from "@mantine/rte";
import { useParams } from "react-router";
import { Logo, Spinner } from "../components";
import { Http } from "../services";
import { Routes } from "../constants";

const Document: React.FC = () => {
  const [busy, setBusy] = useState(true);
  const { id } = useParams();
  const [text, setText] = useState<string>();

  useEffect(
    () => {
      const getDocument = async () => {
        try {
          const response = await Http.get<{ text: string }>(Routes.Document, {
            id,
          });

          if (response.text) setText(response.text);
        } catch {}

        setBusy(false);
      };

      getDocument();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box
      component="main"
      sx={{
        maxWidth: "510px",
        mb: 10,
        ml: "auto",
        mr: "auto",
        mt: 10,
      }}
    >
      <Logo />
      {busy ? <Spinner /> : <RichTextEditor readOnly value={text} />}
    </Box>
  );
};

export default Document;
