import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#757FEF",
    },
    secondary: {
      main: "#818093",
    },
    error: {
      main: "#EE368C",
    },
    warning: {
      main: "#FFBC2B",
    },
    info: {
      main: "#2DB6F5",
    },
    success: {
      main: "#00B69B",
    },
    text: {
      primary: "#5B5B98",
      secondary: "#5B5B98",
      disabled: "#5B5B98",
    },
  },

  typography: {
    fontSize: 12,
  },
});

export default theme;
