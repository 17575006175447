import React from "react";
import { NavLink } from "react-router-dom";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";

const SidebarNav = styled("nav")(() => ({
  background: "#fff",
  boxShadow: "0px 4px 20px rgba(47, 143, 232, 0.07)",
  display: "flex",
  height: "100vh",
  justifyContent: "center",
  left: 0,
  overflowY: "auto",
  padding: "30px 10px",
  position: "fixed",
  top: 0,
  transition: "350ms",
  width: "300px",
  zIndex: "10",
}));

const SidebarWrap = styled("div")(() => ({
  width: "100%",
}));

export const Sidebar: React.FC<{
  toogleActive: () => void;
}> = ({ toogleActive }) => {
  return (
    <>
      <div className="leftSidebarDark">
        <SidebarNav className="LeftSidebarNav">
          <SidebarWrap>
            <Box
              sx={{
                mb: "20px",
                px: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <NavLink to="/">
                <img
                  alt="Logo"
                  className="black-logo"
                  src="/images/logo.png"
                  style={{
                    borderRadius: 20,
                  }}
                  width={100}
                />
              </NavLink>
              <IconButton
                onClick={toogleActive}
                size="small"
                sx={{
                  background: "rgb(253, 237, 237)",
                  display: { lg: "none" },
                  position: "absolute",
                  right: 15,
                  top: 15,
                }}
              >
                <ClearIcon />
              </IconButton>
            </Box>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </div>
    </>
  );
};
