import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import GamepadIcon from '@mui/icons-material/Gamepad';
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Role } from "../../constants";
import { TranslationKey } from "../../i18n";

export interface SidebarItem {
  title: TranslationKey;
  path: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  iconClosed?: boolean;
  iconOpened?: boolean;
  roles?: Role[];
  subNav?: SidebarItem[];
}

export const SidebarData: SidebarItem[] = [
  {
    title: TranslationKey.Home,
    path: "/admin-home",
    icon: HomeIcon,
  },
  {
    title: TranslationKey.AdminUsers,
    path: "/admin-users",
    icon: GroupIcon,
    roles: [Role.Admin],
  },
  {
    title: TranslationKey.Documents,
    path: "/documents",
    icon: DocumentScannerIcon,
    roles: [Role.Admin],
  },
  {
    title: TranslationKey.Recruitments,
    path: "/recruitments",
    icon: GamepadIcon,
    roles: [Role.Admin],
  },
  {
    title: TranslationKey.ReportedUsers,
    path: "/reported-users",
    icon: NoAccountsIcon,
    roles: [Role.Admin],
  },
  {
    title: TranslationKey.Users,
    path: "/users",
    icon: PeopleOutlineIcon,
    roles: [Role.Admin],
  },
];
