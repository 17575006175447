import React from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { ButtonWithIcon } from "../components";

export const FormBox: React.FC<{
  busy: boolean;
  children: React.ReactNode;
  submitButtonChildren: React.ReactNode;
  submitButtonIcon: React.ReactNode;
  onSubmit: (event: any) => void;
}> = ({ busy, children, submitButtonChildren, submitButtonIcon, onSubmit }) => {
  return (
    <Box component="form" noValidate onSubmit={onSubmit}>
      <Box
        sx={{
          background: "#fff",
          padding: "30px 20px",
          borderRadius: "10px",
          mb: "20px",
        }}
        className="bg-black"
      >
        <Grid container alignItems="center" spacing={2}>
          {children}
        </Grid>
      </Box>
      <ButtonWithIcon
        disabled={busy}
        fullWidth
        icon={submitButtonIcon}
        type="submit"
      >
        {submitButtonChildren}
      </ButtonWithIcon>
    </Box>
  );
};
