export enum ActionIdentifier {
  Language = "LANGUAGE",
  LoggedIn = "LOGGEDIN",
}

export enum Gender {
  Female = 1,
  Male,
  Other,
}

export enum StatsKeys {
  Users = "users",
}

export enum VoiceChatOption {
  No = -1,
  Yes = 1,
  Either,
}
