import { useState, useEffect } from "react";
import { ActionIdentifier } from "../constants";

let globalState: Record<string, any> = {};

let listeners: React.Dispatch<React.SetStateAction<Record<string, any>>>[] = [];

let actions: Record<string, any> = {};

export const useStore = (
  shouldListen = true
): [
  Record<string, any>,
  (actionIdentifier: ActionIdentifier, payload: any) => void
] => {
  const setState = useState(globalState)[1];

  const dispatch = (actionIdentifier: string, payload: any) => {
    const newState = actions[actionIdentifier](globalState, payload);
    globalState = { ...globalState, ...newState };

    for (const listener of listeners) {
      listener(globalState);
    }
  };

  useEffect(() => {
    if (shouldListen) {
      listeners.push(setState);
    }

    return () => {
      if (shouldListen) {
        listeners = listeners.filter((li) => li !== setState);
      }
    };
  }, [setState, shouldListen]);

  return [globalState, dispatch];
};

export const initStore = (
  userActions: Record<string, any>,
  initialState: Record<string, any>
) => {
  if (initialState) {
    globalState = { ...globalState, ...initialState };
  }

  actions = { ...actions, ...userActions };
};
