import React from "react";
import { Link, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ActionIdentifier } from "../constants";
import { AdminLanguageKey, Language } from "../i18n";
import { useStore } from "../store";

export const LanguageSwitch: React.FC = () => {
  const dispatch = useStore(false)[1];
  const { i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    dispatch(ActionIdentifier.Language, language);
    i18n.changeLanguage(language);
    localStorage.setItem(AdminLanguageKey.name, language);
  };

  return (
    <Stack
      sx={{
        alignItems: "center",
        display: "flex",
        mt: "10px",
      }}
    >
      <div dir="ltr" style={{ display: "inline-block" }}>
        <Link component="button" onClick={() => changeLanguage(Language.en)}>
          English
        </Link>
        <span className="ml-10px mr-10px"></span>
        <Link component="button" onClick={() => changeLanguage(Language.ja)}>
          日本語
        </Link>
      </div>
    </Stack>
  );
};
