import React from "react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import BlockIcon from "@mui/icons-material/Block";
import SaveIcon from "@mui/icons-material/Save";
import { ButtonWithIcon, FormSaveButton } from ".";
import { TranslationKey } from "../i18n";

export const SaveCancelButtonGroup: React.FC<{
  busy: boolean;
  formSaveButton?: FormSaveButton;
  cancelHandler: (event: any) => void;
  saveHandler: (event: any) => void;
}> = ({ busy, formSaveButton, cancelHandler, saveHandler }) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        display: "inline-block",
        mt: "10px",
      }}
    >
      <ButtonWithIcon
        color={formSaveButton?.color || "success"}
        icon={
          formSaveButton?.icon || <SaveIcon sx={{ color: "#fff !important" }} />
        }
        disabled={busy}
        onClick={saveHandler}
      >
        {formSaveButton?.label ? (
          t(formSaveButton.label)
        ) : busy ? (
          <>{t(TranslationKey.Saving)}</>
        ) : (
          <>{t(TranslationKey.Save)}</>
        )}
      </ButtonWithIcon>

      <ButtonWithIcon
        color="error"
        icon={<BlockIcon sx={{ color: "#fff !important" }} />}
        disabled={busy}
        onClick={cancelHandler}
      >
        {t(TranslationKey.Cancel)}
      </ButtonWithIcon>
    </Stack>
  );
};
