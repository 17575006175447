import React from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select as MaterialSelect,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslationKey } from "../i18n";

export const Select: React.FC<{
  id: string;
  allowNone?: boolean;
  disabled?: boolean;
  formProperty: any;
  label: TranslationKey;
  md?: number;
  options: Record<string, any>[];
  textPropertyName?: string;
  translateTextProperty?: boolean;
  valuePropertyName?: string;
}> = ({
  id,
  allowNone,
  disabled,
  formProperty,
  label,
  md = 12,
  options,
  textPropertyName = "name",
  translateTextProperty = false,
  valuePropertyName = "id",
}) => {
  const { t } = useTranslation();

  return (
    <Grid item md={md} sm={12} xs={12}>
      <Typography
        component="label"
        sx={{
          display: "block",
          fontWeight: "500",
          fontSize: "14px",
          mb: "10px",
        }}
      >
        {t(label)}
      </Typography>
      <FormControl fullWidth>
        <MaterialSelect
          id={id}
          disabled={disabled}
          error={!formProperty.valid}
          value={formProperty.value === null ? "" : formProperty.value}
          onBlur={formProperty.blurHandler}
          onChange={formProperty.changeHandler}
        >
          {allowNone && (
            <MenuItem key="none" value="">
              {t(TranslationKey.None)}
            </MenuItem>
          )}
          {options.map((item) => (
            <MenuItem
              key={item[valuePropertyName]}
              value={item[valuePropertyName]}
            >
              {translateTextProperty
                ? t(item[textPropertyName])
                : item[textPropertyName]}
            </MenuItem>
          ))}
        </MaterialSelect>
        {formProperty.error && (
          <FormHelperText error={!formProperty.valid}>
            {formProperty.error}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};
