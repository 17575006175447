import React from "react";
import {
  Box,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { TranslationKey } from "../i18n";
import { UsersStats } from "../models";

export const UsersStatsView: React.FC<{
  stats?: UsersStats;
}> = ({ stats }) => {
  const { t } = useTranslation();

  return (
    <>
      {stats && (
        <Card
          sx={{
            borderRadius: "10px",
            boxShadow: "none",
            mb: "15px",
            p: "30px 20px 20px",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Box
              sx={{
                alignContent: "center",
                background: "rgba(85, 112, 241, 0.12)",
                borderRadius: "5px",
                height: "50px",
                textAlign: "center",
                width: "50px",
              }}
              className="mr-15px"
            >
              <PeopleAltIcon fontSize="large" />
            </Box>
            <Box>
              <Typography component="p" sx={{ fontSize: "13px", mb: "5px" }}>
                {t(TranslationKey.Users)}
              </Typography>
              <Typography variant="h1" sx={{ fontSize: 28, fontWeight: 700 }}>
                {stats.total}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              mt: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 500,
                mb: "10px",
              }}
            >
              By age:
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                mb: "15px",
              }}
            >
              <Table className="dark-table">
                <TableHead sx={{ background: "#F7FAFF" }}>
                  <TableRow>
                    <TableCell>{`<12`}</TableCell>
                    <TableCell>12 ~ 15</TableCell>
                    <TableCell>15 ~ 20</TableCell>
                    <TableCell>20 ~ 25</TableCell>
                    <TableCell>25 ~ 30</TableCell>
                    <TableCell>30 ~ 35</TableCell>
                    <TableCell>35 ~ 40</TableCell>
                    <TableCell>{`>40`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{stats.lessThan12}</TableCell>
                    <TableCell>{stats.from12To15}</TableCell>
                    <TableCell>{stats.from15To20}</TableCell>
                    <TableCell>{stats.from20To25}</TableCell>
                    <TableCell>{stats.from25To30}</TableCell>
                    <TableCell>{stats.from30To35}</TableCell>
                    <TableCell>{stats.from35To40}</TableCell>
                    <TableCell>{stats.moreThan40}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              mt: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 500,
                mb: "10px",
              }}
            >
              By gender:
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                mb: "15px",
              }}
            >
              <Table className="dark-table">
                <TableHead sx={{ background: "#F7FAFF" }}>
                  <TableRow>
                    <TableCell>Female</TableCell>
                    <TableCell>Male</TableCell>
                    <TableCell>Other</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{stats.female}</TableCell>
                    <TableCell>{stats.male}</TableCell>
                    <TableCell>{stats.other}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      )}
    </>
  );
};
