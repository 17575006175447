import React from "react";
import { Button } from "@mui/material";

export const ButtonWithIcon: React.FC<{
  children: React.ReactNode;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  disabled: boolean;
  fullWidth?: boolean;
  icon: React.ReactNode;
  type?: "button" | "submit" | "reset";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}> = ({
  children,
  color = "primary",
  disabled,
  fullWidth,
  icon,
  type = "button",
  onClick,
}) => {
  return (
    <Button
      className="mr-10px"
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
      startIcon={icon}
      sx={{
        borderRadius: "10px",
        color: "#fff !important",
        fontSize: "14px",
        fontWeight: "500",
        padding: "10px 30px",
        textTransform: "capitalize",
      }}
      type={type}
      variant="contained"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
