import { useState, useEffect } from "react";
import "./CurrentDate.css";

function CurrentDate() {
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    /* @ts-ignore */
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const date = new Date();
    setCurrentDate(formatter.format(date));
  }, []);

  return (
    <div className="currentDate">
      <i className="ri-calendar-2-line"></i>
      {currentDate}
    </div>
  );
}

export default CurrentDate;
